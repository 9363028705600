@use "@/mixins";

.featured {
    padding: 0 9%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    @include mixins.media-query(s) {
        padding: 0 25px;
        gap: 70px;
    }

    li {
        flex-grow: 1;
        @include mixins.size();
    }
}
