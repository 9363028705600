@use "@/mixins";

.title {
    isolation: isolate;
    align-self: flex-start;
    width: 100%;
    padding: 0 12%;
    display: flex;
    align-items: center;
    gap: max(10px, 5%);

    h2 {
        position: relative;
        padding-left: 25px;

        @include mixins.media-query(s) {
            margin-left: 0;
            font-size: 1.1em;
        }

        @include mixins.pseudo($pos: 40% auto auto 0) {
            @include mixins.size(0.35em);
            aspect-ratio: 1 / 1;
            background-color: hsl(var(--accent-1));
            border-radius: 50%;
        }
    }

    span {
        height: 1px;
        max-width: 350px;
        flex: 1 0 150px;
        background-color: hsl(var(--secondary-2));

        @include mixins.media-query(s) {
            max-width: 250px;
            flex: 1;
        }
    }
}
