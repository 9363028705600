@use "@/mixins";

.button {
    align-self: center;
    padding: 0.5em 1em;
    min-height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    color: hsl(var(--secondary-1));
    font-weight: bold;
    background-color: hsl(var(--primary-2));
    border-radius: var(--radius, 5px);
    @include mixins.box-shadow();
    transition: scale 0.3s;

    &:active {
        scale: 0.95;
    }
}
