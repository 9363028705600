@use "@/mixins";

.home {
    position: relative;
    min-height: 100svh;
    display: flex;
    flex-direction: column;

    @include mixins.media-query(s) {
        align-items: center;
    }
}
