@use "@/mixins";

$card-padding: 25px;
$card-gap: 1.2em;
$circle-size: 60px;
$title-font-size: 1.2em;

.card {
    position: relative;
    padding: $card-padding;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: $card-gap;
    background-color: hsl(var(--primary-1));
    border: 1px solid hsl(var(--primary-2));
    border-radius: 7px;
    @include mixins.box-shadow();
    transition: translate 0.5s;

    @include mixins.hover-shard(
        $top: calc($card-padding + $circle-size + $card-gap + $title-font-size * 0.75)
    );

    &:hover {
        translate: 0 -5px;
    }

    .circle {
        position: relative;
        @include mixins.size($circle-size);
        aspect-ratio: 1/1;
        color: hsl(var(--accent-1));

        .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            translate: -50% -50%;
            color: hsl(var(--primary-1));
        }
    }

    h3 {
        font-size: $title-font-size;
    }

    p {
        margin: auto;
        text-align: center;
    }
}
