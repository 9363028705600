@use "@/mixins";

$card-padding-vertical: 2em;
$folder-size: 40px;
$icons-margin-bottom: 1.3em;
$title-font-size: 1.2em;

.card {
    position: relative;
    margin: 0 auto;
    padding: $card-padding-vertical 1.75em;
    @include mixins.size();
    max-width: 500px;
    display: flex;
    flex-direction: column;
    background-color: hsl(var(--primary-2));
    border: 1px solid hsl(var(--primary-3));
    border-radius: 5px;
    transition: translate 0.5s;

    @include mixins.media-query(s) {
        max-width: unset;
    }

    @include mixins.pseudo($loc: before) {
        z-index: -1;
        opacity: 0;
        @include mixins.size();
        @include mixins.box-shadow($down: 5px, $blur: 25px);
        transition: opacity 0.5s;
    }

    @include mixins.hover-shard(
        $top:
            calc(
                $card-padding-vertical + $folder-size + $icons-margin-bottom + $title-font-size *
                    0.75
            )
    );

    &:hover {
        translate: 0 -5px;

        &::before {
            opacity: 1;
        }

        .title a {
            color: hsl(var(--accent-1));
        }
    }

    .title {

        a {
            margin-bottom: 0.2em;
            width: fit-content;
            display: flex;
            font-size: $title-font-size;
            transition: color 0.3s;
        }

        span {
            color: hsl(var(--secondary-2));
            font-size: 0.85em;
            text-transform: capitalize;
        }
    }

    p {
        margin: 2em 0;
        color: hsl(var(--secondary-1));
        transition: color 0.3s;
    }
}

.icons {
    margin-bottom: $icons-margin-bottom;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .folder {
        color: hsl(var(--accent-1));
    }
}

.links {
    display: flex;
    gap: 10px;

    a {
        display: grid;
        place-items: center;
        color: hsl(var(--secondary-2));
        transition:
            translate 0.3s,
            color 0.3s;

        &:nth-child(2) {
            margin-bottom: 2px;
        }

        &:hover {
            color: hsl(var(--accent-1));
            translate: 0 -2px;
        }
    }
}

.skills {
    margin-top: auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    color: hsl(var(--secondary-2));
    font-family: var(--font-mono);
    font-size: 0.8em;
}
