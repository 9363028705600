@use "@/mixins";

.things {
    position: relative;
    padding: max(6%, 50px) 0 max(8%, 60px);
    display: flex;
    flex-direction: column;
    gap: 50px;

    @include mixins.pseudo($loc: before, $pos: auto auto 0 0) {
        background-color: hsl(var(--primary-2));
        @include mixins.size();
        transform: skewY(-2deg);
        @include mixins.box-shadow($down: 0px);
    }
}

.cards {
    padding: 0 65px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: max(5%, 50px);

    @include mixins.media-query(s) {
        padding: 0 25px;
    }

    li {
        flex: 0 1 250px;
    }
}
